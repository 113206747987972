<template>
  <div class="display_detail">
    <div v-if="ifShowPage">
      <div class="title">{{outcomeDetail.title}}</div>
        <div class="date">{{outcomeDetail.updated_at}}</div>
        <div class="content">
          <!-- <div class="content_text" v-html="outcomeDetail.desc"></div> -->
          <div class="content_text" v-html="outcomeContent"></div>
          <div class="read_num">阅读 {{outcomeDetail.read_count}}</div>
        </div>
    </div>
  </div>
</template>

<script>
import {getOutcomeRead} from "@/api/api"
export default {
  data() {
    return {
      contentText: "成果展示",
      outcomeDetail: {},
      outcomeContent: "",
      ifShowPage: false,
      prefix: this.baseUrl + "/upload/images",
      ifShowPage: false,
    }
  },
  created() {
    this.init()
  },
  
  mounted() {
    
  },
  methods: {
    async init() {
      await getOutcomeRead({
        project_id: this.$route.query.projectId,
        id: this.$route.query.outcome_id
      }).then(res => {
        console.log("res成果1：",res)
        this.outcomeDetail = res
        // console.log("outcomeDetail",this.outcomeDetail.desc.replaceAll("/upload/images",this.prefix))
        // let prefix = this.baseUrl + "/upload/images" //拼接图片链接前缀
        this.outcomeContent = this.outcomeDetail.desc.replace(/\/upload\/images/g,this.prefix)
        console.log("outcomeContent",this.outcomeContent)
        this.ifShowPage = true //数据获取完成后显示页面
      }) 
    },
  }
}
</script>

<style lang="scss" scoped>
.display_detail{
  padding: 20px 15px;
  .title{
    font-size: 20px;
    font-weight: bold;
    color: #0A0A0A;
    text-align: left;
  }
  .date{
    font-size: 14px;
    color: #101010;
    text-align: left;
    margin: 15px 0;
  }
  .content{
    .content_text{
      text-align: left;
    }
  }
  .read_num{
    font-size: 16px;
    color: #989898;
    text-align: left;
    margin-top: 30px;
  }
}
</style>